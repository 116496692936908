"use client";
import Image from "next/image";
import Link from "next/link";
import { FaArrowAltCircleRight } from "react-icons/fa";
import { GrCloudComputer } from "react-icons/gr";
import Slider from "react-slick";

const TechnologySection = ({
  title = "What Variety of Web Solutions Does",
  colouredTitle = "Our Web Designing Company Offer?",
  description = "What Variety of Web Solutions Does",
  imageSrc = "",
  imageAlt = "",
  items = [],
  bgColor = "",
}) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    //centerMode: true,
    //centerPadding: "60px",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <section className="bg-white py-10 md:py-16 lg:py-20 w-full">
        <div className="max-w-screen-xl mx-7 lg:mx-auto md:flex md:flow-row items-center justify-center">
          <div className="flex-1 md:w-1/3 pr-2 md:pr-10">
            <h3 className="text-black text-2xl">Technology Advantages</h3>
            <h2 className="font-extrabold text-black">
              {title}
              <div className="text-colorOrange-700">{colouredTitle}</div>
            </h2>
            <p
              className="text-black"
              dangerouslySetInnerHTML={{ __html: description }}
            ></p>
            {imageSrc && (
              <Image
                src={imageSrc}
                width={200}
                height={300}
                alt={imageAlt}
                className=""
              />
            )}
          </div>
          <div className="flex-1 md:w-2/3">
            <Slider {...settings}>
              {items.map((item, index) => {
                const [beforeColon, afterColon] = item.name.split(":");
                const bgColor =
                  index % 2 === 0 ? "bg-colorOrange-500" : "bg-neutral-800";
                const textColor =
                  index % 2 === 0 ? "text-neutral-800" : "text-colorOrange-400";
                return (
                  <div key={index} className="p-0 md:p-4 pt-8">
                    <div
                      className={`${bgColor} p-6 rounded-lg shadow-lg min-h-[30rem]`}
                    >
                      <GrCloudComputer
                        className={`self-start w-16 h-16 mr-3 mb-4 ${textColor}`}
                      />
                      <div className={`text-lg font-medium ${textColor}`}>
                        <div className={`text-xl font-bold ${textColor}`}>
                          {item.link ? (
                            <Link
                              href={item.link}
                              className={`text-xl uppercase font-bold mt-4 inline-block ${textColor} hover:text-colorRed-500`}
                            >
                              {beforeColon}
                            </Link>
                          ) : (
                            <div
                              href={item.link}
                              className={`text-2xl uppercase font-bold mt-4 inline-block ${textColor} hover:text-colorRed-500`}
                            >
                              {beforeColon}
                            </div>
                          )}
                        </div>
                        <div className={`text-lg pt-4 ${textColor}`}>
                          {afterColon}
                        </div>
                      </div>
                      {item.link && (
                        <Link
                          href={item.link}
                          className={`text-sm uppercase font-medium mt-4 inline-block ${textColor} hover:text-colorRed-500`}
                        >
                          <FaArrowAltCircleRight
                            size={24}
                            className={`${textColor}`}
                          />
                        </Link>
                      )}
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </section>
    </>
  );
};

export default TechnologySection;
