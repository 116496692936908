"use client";
import { getLatestBlogs } from "@/lib/mdxBlogs";
import Image from "next/image";
import Link from "next/link";
import { useEffect, useState } from "react";

const LatestBlog = ({ category = "" }) => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const fetchedBlogs = await getLatestBlogs({ category });
        setBlogs(fetchedBlogs);
      } catch (error) {
        console.error("Failed to fetch the latest blogs", error);
        setBlogs([]);
      }
    };

    fetchBlogs();
  }, [category]);

  if (blogs && blogs?.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <section className="py-8 md:py-10 lg:py-12 relative">
      <div className="max-w-screen-xl mx-5 lg:mx-auto">
        <h3 className="text-center text-colorOrange-700 font-semibold">
          Latest Blog
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 items-start justify-between text-center">
          {blogs.map((blog) => (
            <div
              key={blog.id}
              className="bg-white shadow-lg border-b-4 border-b-orange-500 h-full"
            >
              <Link href={`/blog/${blog.slug}`} passHref>
                <div className="relative h-[12rem] md:h-56 w-full">
                  <Image
                    src={blog.featuredImage || "/images/blog/default.jpg"}
                    alt={blog.title}
                    fill
                    className="absolute top-0 left-0 object-cover"
                  />
                </div>
                <h3 className="font-bold mb-0 text-lg px-2 pt-2 text-colorGrey-700">
                  {blog.title}
                </h3>
                <div className="mb-0 text-base">
                  {new Date(blog.date).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}{" "}
                </div>
                <div className="my-2 text-base px-4 line-clamp-3 text-colorGrey-900">
                  {blog.excerpt}
                </div>
                <div className="flex flex-col items-center justify-center">
                  <p className="text-sm bg-white hover:bg-orange-600 border border-orange-600 text-orange-600 my-4 hover:text-white font-semibold rounded-md uppercase px-2 py-2 lg:px-4">
                    View Details
                  </p>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-col items-center justify-center mt-10">
        <Link href="/blog">
          <p className=" hover:bg-orange-600 border border-orange-600 text-black hover:text-white font-semibold rounded-md px-6 py-1 lg:px-8 lg:py-2 text-sm w-48 text-center">
            More Blogs
          </p>
        </Link>
      </div>
    </section>
  );
};

export default LatestBlog;
