"use client";
import React from "react";
import Link from "next/link";

const services = {
  "Web Design": [
    { title: "Affordable Web Design", url: "affordable-web-design" },
    {
      title: "AI Powered Website Design and Development",
      url: "ai-powered-website-design-and-development",
    },
    { title: "Bespoke Website Design", url: "bespoke-website-design" },
    { title: "Business Website", url: "business-website" },
    { title: "Community Website Design", url: "community-website-design" },
    { title: "Custom Blog Design", url: "custom-blog-design" },
    {
      title: "Custom Logo Design Services",
      url: "custom-logo-design-services",
    },
    { title: "Custom Website Design", url: "custom-website-design" },
    { title: "Custom WordPress Website", url: "custom-wordpress-website" },
    {
      title: "Landing Page Design Services",
      url: "landing-page-design-services",
    },
    { title: "Membership Site", url: "membership-site" },
    {
      title: "Personal Websites Design and Development",
      url: "personal-websites-design-and-development",
    },
    { title: "Portfolio Website Design", url: "portfolio-website-design" },
    { title: "Responsive Website", url: "responsive-website" },
    { title: "SAAS Website Design Agency", url: "saas-website-design-agency" },
    {
      title: "Shopify Web Design Services",
      url: "shopify-web-design-services",
    },
    { title: "Website Redesign", url: "website-redesign" },
    { title: "White Label Web Design", url: "white-label-web-design" },
    { title: "WinterCMS Design Services", url: "wintercms-design-services" },
  ],

  "Web Development": [
    {
      title: "Bootstrap Development Services",
      url: "bootstrap-development-services",
    },
    {
      title: "Custom Laravel Web Application",
      url: "custom-laravel-web-application",
    },
    {
      title: "Custom Web Application Development",
      url: "custom-web-application-development",
    },
    {
      title: "Ecommerce Development Services",
      url: "ecommerce-development-services",
    },
    { title: "GatsbyJS Development", url: "gatsbyjs-development" },
    { title: "Laravel", url: "laravel" },
    { title: "Laravel Developer", url: "laravel-developer" },
    { title: "NextJS Website Design", url: "nextjs-website-design" },
    { title: "PSD to WordPress Theme", url: "psd-to-wordpress-theme" },
    { title: "React Development Services", url: "react-development-services" },
    {
      title: "React Native Development Services",
      url: "react-native-development-services",
    },
    { title: "Shopify Custom Development", url: "shopify-custom-development" },
    {
      title: "Shopify Theme Customization Services",
      url: "shopify-theme-customization-services",
    },
    {
      title: "Shopware Development Services",
      url: "shopware-development-services",
    },
    { title: "Shopware Theme Development", url: "shopware-theme-development" },
    {
      title: "TailwindCSS Development Services",
      url: "tailwindcss-development-services",
    },
    {
      title: "Vue JS Development Services",
      url: "vue-js-development-services",
    },
    { title: "Web Development", url: "web-development" },
    { title: "Website As A Service", url: "website-as-a-service" },
  ],

  Ecommerce: [
    { title: "Ecommerce", url: "ecommerce" },
    { title: "Ecommerce Management", url: "ecommerce-management" },
    {
      title: "Ecommerce Website Development",
      url: "ecommerce-website-development",
    },
    { title: "Shopify", url: "shopify" },
    { title: "Shopify Maintenance", url: "shopify-maintenance" },
    { title: "Shopify SEO Services", url: "shopify-seo-services" },
    {
      title: "Shopware Design and Devlopment",
      url: "shopware",
    },
    { title: "Woocommerce", url: "woocommerce" },
  ],

  "SEO & Digital Marketing": [
    { title: "AI Automation Consultancy", url: "ai-automation-consultancy" },
    { title: "Analytics and Reporting", url: "analytics-and-reporting" },
    { title: "Content Marketing Services", url: "content-marketing-services" },
    { title: "Content Writing Services", url: "content-writing-services" },
    {
      title: "Conversion Rate Optimization (CRO)",
      url: "conversion-rate-optimization-cro",
    },
    { title: "Digital Marketing", url: "digital-marketing" },
    { title: "Email Marketing", url: "email-marketing" },
    { title: "Keyword Research", url: "keyword-research" },
    { title: "Local SEO Services", url: "local-seo-services" },
    { title: "Monthly SEO Packages", url: "monthly-seo-packages" },
    { title: "Onsite SEO", url: "onsite-seo" },
    { title: "Search Engine Optimization", url: "search-engine-optimization" },
    { title: "SEO Audit", url: "seo-audit" },
    { title: "Social Media Marketing", url: "social-media-marketing" },
  ],

  "Graphic Design & Color Theory": [
    {
      title: "Freelance Graphic Designer in Mumbai",
      url: "freelance-graphic-designer-in-mumbai",
    },
    { title: "Graphic Design Services", url: "graphic-design-services" },
    {
      title: "Product Images Editing Service",
      url: "product-images-editing-service",
    },
    {
      title: "Social Media Design Services",
      url: "social-media-design-services",
    },
    { title: "UX Design Services", url: "ux-design-services" },
    {
      title: "Mobile App Screen Design Services",
      url: "mobile-app-screen-design-services",
    },
  ],

  "Mobile Solutions": [
    {
      title: "Mobile App Development Services",
      url: "mobile-app-development-services",
    },
    { title: "Mobile Solutions", url: "mobile-solutions" },
  ],

  "Other Services": [
    {
      title: "Freelance Website Developer in Mumbai",
      url: "freelance-website-developer-in-mumbai",
    },
    { title: "Managed Website Services", url: "managed-website-services" },
    {
      title: "News Portal Design and Development",
      url: "news-portal-design-and-development",
    },
    { title: "Pay Monthly Websites", url: "pay-monthly-websites" },
    { title: "Portfolio", url: "portfolio" },
    { title: "Refund Policy", url: "refund-policy" },
    { title: "Website Designer Near Me", url: "website-designer-near-me" },
    { title: "Website Hosting", url: "website-hosting" },
    { title: "Website in a Week", url: "website-in-a-week" },
    { title: "Website Maintenance", url: "website-maintenance" },
    { title: "Website Security", url: "website-security" },
    { title: "Website Solutions", url: "website-solutions" },
    { title: "WordPress", url: "wordpress" },
    { title: "WordPress Customization", url: "wordpress-customization" },
    {
      title: "WordPress Plugin Development",
      url: "wordpress-plugin-development",
    },
    { title: "WordPress Website", url: "wordpress-website" },
    {
      title: "WordPress Website Maintenance",
      url: "wordpress-website-maintenance",
    },
  ],
};

const ServicesList = ({ service = "All" }) => {
  const filteredServices =
    service === "All"
      ? services
      : Object.keys(services).includes(service)
        ? { [service]: services[service] }
        : {};

  return (
    <section className="bg-neutral-600 py-7 md:py-10 lg:py-12">
      <div className="max-w-screen-xl mx-5 lg:mx-auto">
        <h3 className="text-center mb-8 lg:mb-10 text-colorOrange-300 font-semibold">
          We love to provide services in the following categories
        </h3>
        <div className="text-sm grid grid-cols-1 md:grid-cols-3 md:gap-8 lg:gap-10">
          {Object.keys(filteredServices).map((category, index) => (
            <div key={index}>
              <h5 className="font-bold text-colorOrange-600 text-lg">
                {category}
              </h5>
              <ul className="leading-relaxed">
                {filteredServices[category].map((service, idx) => (
                  <li key={idx}>
                    <Link
                      href={`/${service.url}`}
                      className="text-white text-sm hover:text-colorOrange-700"
                    >
                      {service.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <div className="flex flex-col items-center justify-center">
          <Link
            href="/services"
            className="text-white border-2 border-colorOrange-300 py-2 px-6 rounded-lg"
          >
            More Services
          </Link>
        </div>
      </div>
    </section>
  );
};

export default ServicesList;
