"use client";
import { useState } from "react";
import Link from "next/link";

const primaryColors = "bg-colorOrange-500 text-neutral-800";
const secondaryColors = "bg-neutral-800 text-white";

const ServicesHome = ({
  title = "What Variety of Web Solutions Does",
  colouredTitle = "Our Web Designing Company Offer?",
  description = "What Variety of Web Solutions Does",
  imageSrc = "",
  imageAlt = "",
  items = [
    {
      tabTitle: "Web Design",
      title: "Web Design Services",
      description:
        "Do you want a beautiful, user-friendly website? We use WordPress's variety, Gatsby.js' speed, or Next.js' cutting-edge capabilities to turn your ideas into a website that looks great, functions great, and stands out online.",
      icon: "📱",
    },
    {
      tabTitle: "Ecommerce",
      title: "Ecommerce Website Design",
      description:
        "Ecommerce Website Design helps you maximise online business profits. Our web development agency develops reliable, user-friendly Ecommerce solutions for all types of enterprises. Your Shopify, Shopware, or WooCommerce store may look better and sell more with us.",
      icon: "📊",
    },
    {
      tabTitle: "Web Development",
      title: "Web Development",
      description:
        "Our Web Development services boost your online visibility. We combine Laravel with React to create powerful, easy-to-use web apps. Our developers can create complicated online portals and interactive web apps with a great user experience and reliable performance.",
      icon: "🔍",
    },
    {
      tabTitle: "Mobile App",
      title: "Mobile App Development",
      description:
        "Our graphic design services are a blank canvas waiting to be filled with your uniqueness and innovative ideas. We bring your ideas to life with eye-catching visuals that make your point. Our skilled designers create works that are both aesthetically appealing and cohesive.",
      icon: "📈",
    },
  ],
}) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const getTabBackground = (index) => {
    return index % 2 === 0 ? primaryColors : secondaryColors;
  };

  return (
    <section className="py-12 services-home">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-left">
        <h2 className="text-3xl lg:text-5xl font-extrabold text-left text-neutral-800">
          {title}
          <span className="text-colorOrange-600">{colouredTitle}</span>
        </h2>
        {/* Tabs Navigation */}
        <div className="mt-4 flex space-x-1 border-b-2 border-gray-300">
          {items.map((service, index) => (
            <button
              key={index}
              className={`py-2 px-4 text-lg font-semibold border-b-4 ${
                activeTab === index
                  ? "border-colorOrange-600 text-colorOrange-600"
                  : "border-transparent text-gray-500"
              } ${getTabBackground(index)} focus:outline-none`}
              onClick={() => handleTabClick(index)}
            >
              {service.tabTitle}
            </button>
          ))}
        </div>

        {/* Tabs Content */}
        <div className="mt-2">
          <div className="p-6 rounded-lg shadow-md">
            <div className="flex items-center">
              <div className="h-20 w-20 rounded-full bg-white text-4xl flex items-center justify-center">
                {items[activeTab].icon}
              </div>
              <h3 className="ml-4 text-2xl leading-6 font-bold">
                {items[activeTab].title}
              </h3>
            </div>
            <p
              className="mt-4 text-lg"
              dangerouslySetInnerHTML={{ __html: items[activeTab].description }}
            ></p>
            {items[activeTab].link && (
              <Link
                href={items[activeTab].link}
                className="text-base font-semibold py-1 px-4 mt-4 inline-block bg-colorOrange-500 text-neutral-800 hover:bg-colorOrange-600"
              >
                Learn More
              </Link>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesHome;
